import React from "react"
import { Link } from "gatsby"

import { JobFeature } from "../components/job-components"
import SuccessStory from "../components/success-story"

const SuccessStoryMiracad = ({ data }) => {
  return (
    <SuccessStory
      title="Anonymizing ultra high resolution images"
      customerName="MiraCAD"
      customerLocation="Calgary, Canada"
      customerDescription="MiraCAD offers reality capture, visualization, and digital strategy implementation for industrial facilities. The MiraCAD team combines engineers, surveyors, designers, and programmers who work with facility owners to extract the most value from 3D data and high-resolution photography."
      customerIndustries="Reality capture, Geospatial"
      link="https://www.miracad.com/"
      logo=""
      style={{
        background: "url(/images/datacenter.jpg) no-repeat",
        backgroundPositionY: "center",
        backgroundSize: "cover",
      }}
    >
      <h2 className="my-3">Challenge</h2>
      <p>
        MiraCAD carries out 3D data capture using laserscanners and mobile mapping devices all over Canada. For a
        particular project, they were planning to capture high-resolution images with 42 data stations x 12.000 cube
        images per data station with resolutions of up to 510 Megapixels.
      </p>
      <p>In total, they forecasted to collect between 500 and 600 thousand images (ca. 25 Terabytes of data).</p>

      <h2 className="my-3">Solution</h2>
      <p>
        Due to a large amount of data and enormous image resolution, <Link to="/container/">Celantur Container</Link>{" "}
        was deployed on MiraCAD's local infrastructure, providing an automated and scalable solution to blur personal
        information, while avoiding possible network throughput bottlenecks.
      </p>

      <h2 className="my-3">Celantur's contribution</h2>
      <JobFeature>Enabling MiraCAD to blur personal data directly on ultra-high-resolution images</JobFeature>
      <JobFeature>On-premise deployment on local infrastructure</JobFeature>
      <JobFeature>Privacy of citizens protected</JobFeature>
    </SuccessStory>
  )
}

export default SuccessStoryMiracad
